import React, { useState, useEffect } from 'react'
import withData from '@hoc/withData'
import Head from 'next/head'
import { Col, Row, Notification, Panel, IconButton, Icon } from 'rsuite'
import { Image } from 'semantic-ui-react'
import { NextSeo, ProductJsonLd } from 'next-seo'
import Categories from '../components/Modules/Main/Categories'
import { getStorage, setSync, useSession, useStorage, useSync } from '../utils/Storken'
import { setCost, slugify, getDesc, getMinPrice, getSeoSizeName } from '../services/CustomerActions'
import SiteBanner from '../components/Modules/Main/SiteBanner'
import DiscountNotifi from '../components/Modules/Basket/DiscountNotifi'
import DefaultSeo from '../components/Modules/Main/Seo'
import BannerSlider from '../components/Modules/Main/BannerSlider'

const Index = (props) => {
  const [editMode, setEditMode] = useSession('editMode')
  const [, setEditingOrder] = useSession('editingOrder')
  const [, setBasket] = useStorage('baskets')
  const [, setCustomer] = useStorage('customer')
  const [show, setShow] = useState(true)
  const [active] = useSync('active')
  useEffect(() => {
    console.log('page_props-->', props)
  }, [props])

  const NotifiContent = () => (
    <Panel>
      <br />
      <IconButton
        onClick={() => {
          setEditMode(false)
          setEditingOrder({})
          setBasket([])
          setSync('basket', [])
          setCustomer({})
          setCost()
        }}
        icon={<Icon icon='close' />}
      >Düzenleme Modundan Çık
      </IconButton>
      <br />
    </Panel>
  )
  useEffect(() => {
    if (editMode) {
      if (show) {
        Notification.open({
          title: 'Sipariş Düzenleme Modu Aktif',
          description: <NotifiContent />
        })
      }
      setShow(false)
    }
  }, [editMode, show])

  useEffect(() => {
    // console.log('main props', props)
  }, [props])


  return (
    <div>
      <Head>
        {
          props?.url.asPath === '/'
            ? <title>Köyden Gelsin</title>
            : <title>{active.name} | Köyden Gelsin</title>
        }
        <meta name='viewport' content='initial-scale=1.0, width=device-width' />
        <meta name='description' content='Doğal köy ürünleri bir tık yakınınızda!' />
      </Head>
      <Row>
        <Col xs={24}>
          <BannerSlider />
        </Col>
        <Col xs={24}>
          <Categories {...props} />
        </Col>
      </Row>
      {
        // eslint-disable-next-line react/prop-types,react/destructuring-assignment
        props?.url?.query?.products?.map(e => (
          e
            ? <Seo key={e?.name} name={e?.name} pd={e} />
            : null
        ))
      }
      <DefaultSeo />
    </div>
  )
}
const Seo = ({ name, pd }) => (
  <>
    <ProductJsonLd
      keyOverride={name}
      productName={`${name}`}
      images={[pd?.photos[0]?.data_url]}
      description={getDesc(pd?.description)}
      brand='Köyden Gelsin'
      reviews={[
        {
          author: {
            type: 'Person',
            name: 'Hande Başoğlu'
          },
          datePublished: '2021-01-06T03:37:40Z',
          reviewBody:
          'Mükemmel bir ürün.',
          name: 'Çok kaliteli herkese tavsiye ederim!!!',
          reviewRating: {
            bestRating: '5',
            ratingValue: '5',
            worstRating: '1'
          },
          publisher: {
            type: 'Organization',
            name: 'TwoVit'
          }
        }
      ]}
      aggregateRating={{
        ratingValue: '5.0',
        reviewCount: '1500'
      }}
      offers={[
        {
          price: pd?.sizeprice ? getMinPrice(pd?.sizeprice) : '100',
          priceCurrency: 'TRY',
          priceValidUntil: '2020-11-05',
          itemCondition: 'https://schema.org/NewCondition',
          availability: 'https://schema.org/InStock',
          url: `https://www.koydengelsinn.com/urun/${slugify(name)}`,
          seller: {
            name: 'Köyden Gelsin'
          }
        }
      ]}
      sku={pd?._id}
      mpn={pd?._id}
    />
    <NextSeo
      openGraph={{
        type: 'website',
        url: `https://www.koydengelsinn.com/urun/${slugify(name)}`,
        title: `${pd?.name}`,
        '@id': pd?._id,
        description:getDesc(pd?.description),
        images: [
          {
            url: `${pd?.photos[0]?.data_url}`,
            width: 800,
            height: 600,
            alt: 'Og Image Alt'
          }
        ]
      }}
    />
  </>
)

export default withData(Index)
